span {
    @apply font-bold;
}

h1 {
    @apply text-5xl;
}

h2 {
    @apply text-lg; 
}

h3 {
    @apply text-lg; 
}